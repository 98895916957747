




































import { Component, Prop, Watch } from 'vue-property-decorator';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import CommunityUser from '@/models/graphql/CommunityUser';
import EntityType from '@/utils/enums/EntityType';
import GroupType from '@/utils/enums/chat/GroupType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import Exhibitor from '@/models/graphql/Exhibitor';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import Session from '@/models/graphql/Session';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import Deal from '@/models/graphql/Deal';
import Speaker from '@/models/graphql/Speaker';
import MessageGroup from '@/models/graphql/MessageGroup';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

/* eslint-disable no-underscore-dangle */
@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonMessage extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: CommunityUser | Exhibitor | Session | LargeProduct | Product | Deal;

  @Prop({ required: false })
  protected readonly filterSlotActionResult!: { type: string; limit: number; code: string }[];

  @Prop({
    required: false,
    default: null,
  })
  protected readonly messageGroupData!: MessageGroup;

  @Prop({ required: true })
  private entity!: EntityType;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }) => Promise<void>;

  private communityUserVisibility = true;

  private action = this.actionResult;

  private isMessageFeatureEnabled = false;

  private get fetchIsVisibleAndNotBlock(): boolean {
    return !!this.getter<boolean>('fetchIsVisibleAndNotBlock');
  }

  private get fetchIsIsAccepted(): boolean {
    return !!this.getter<boolean>('fetchIsIsAccepted');
  }

  private get text(): string {
    return `${this.$t('actions.message')}`;
  }

  private get showMessageButton(): boolean {
    if (this.entity === EntityType.EXHIBITOR
        && (this.action as Exhibitor)._chatReps
        && ((this.action as Exhibitor)._chatReps as CompanyUserRole[]).length > 0) {
      return true;
    }
    if (this.entity === EntityType.LARGE_PRODUCT
        && (this.action as LargeProduct).exhibitor
        && (this.action as LargeProduct).exhibitor?._chatReps
        && ((this.action as LargeProduct).exhibitor?._chatReps as CompanyUserRole[]).length > 0) {
      return true;
    }
    if (this.entity === EntityType.PRODUCT
        && (this.action as Product).exhibitor
        && (this.action as Product).exhibitor?._chatReps
        && ((this.action as Product).exhibitor?._chatReps as CompanyUserRole[]).length > 0) {
      return true;
    }
    if (this.entity === EntityType.DEAL
        && (this.action as Deal).exhibitor
        && (this.action as Deal).exhibitor?._chatReps
        && ((this.action as Deal).exhibitor?._chatReps as CompanyUserRole[]).length > 0) {
      return true;
    }
    if (this.entity === EntityType.SESSION
        && (!this.messageGroupData || this.messageGroupData?.active !== false)
        && (this.action as Session).showLiveChat
        && (this.action as Session).moderators.length > 0
    ) {
      return true;
    }
    if (this.entity === EntityType.SPEAKER
        && (this.action as Speaker).user
    ) {
      return true;
    }
    if (this.entity === EntityType.USER) {
      return this.fetchIsIsAccepted;
    }
    return false;
  }

  private get showMessageModal(): boolean {
    return this.entity
        && [EntityType.EXHIBITOR,
          EntityType.LARGE_PRODUCT,
          EntityType.PRODUCT,
          EntityType.DEAL,
        ].includes(this.entity);
  }

  private get isDisplayed(): boolean {
    return !!(this.actionType === this.ActionButtonType.MESSAGE
            && [this.EntityTypeEnum.USER,
              this.EntityTypeEnum.EXHIBITOR,
              this.EntityTypeEnum.PRODUCT,
              this.EntityTypeEnum.LARGE_PRODUCT,
              this.EntityTypeEnum.DEAL,
              this.EntityTypeEnum.SPEAKER,
              this.EntityTypeEnum.SESSION].includes(this.entity)
            && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
            && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)?.enabled
            && this.showMessageButton
            && this.communityUserVisibility)
        && (this.EntityTypeEnum.USER === this.entity
            || (String(this.entity) !== String(this.EntityTypeEnum.USER) && this.isMessageFeatureEnabled));
  }

  created(): void {
    if ([this.EntityTypeEnum.EXHIBITOR,
      this.EntityTypeEnum.PRODUCT,
      this.EntityTypeEnum.LARGE_PRODUCT,
      this.EntityTypeEnum.DEAL].includes(this.entity)) {
      if (this.filterSlotActionResult && this.filterSlotActionResult[0]) {
        this.isMessageFeatureEnabled = this.filterSlotActionResult[0].limit === -1 || this.filterSlotActionResult[0].limit > 0;
      } else {
        this.isMessageFeatureEnabled = false;
      }
    } else {
      this.isMessageFeatureEnabled = true;
    }
    this.buttonsDisplay();
  }

  @Watch('reRender')
  @Watch('fetchIsIsAccepted')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('fetchIsVisibleAndNotBlock', { deep: true })
  private updateCommunityUserVisibility(): void {
    if (this.entity === EntityType.USER) {
      this.communityUserVisibility = this.fetchIsVisibleAndNotBlock;
    }
  }

  private openConversation(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    this.logStats(StatLoggerCategories.MESSAGE, StatLoggerActions.ADD, '');
    if (this.showMessageModal) {
      this.$eventsBus.emit('request-information-modal', {
        data: this.action,
        entityType: this.entityType,
      });
      return;
    }
    switch (this.entity) {
      case EntityType.USER:
        this.openSelectedConversation({
          myUid: this.authUser.uid,
          users: [
            this.authUser,
            this.action as CommunityUser,
          ],
          groupType: [GroupType.DIRECT],
        });
        break;
      case EntityType.SESSION: {
        const session = Session.hydrate(this.action);
        if (session && session.moderators.length > 0) {
          this.openSelectedConversation({
            myUid: this.authUser.uid,
            users: session.moderators || [],
            groupType: [GroupType.GROUP],
            targetUid: this.entityUid,
            targetType: this.entityType,
            targetData: session,
          });
        }
        break;
      }
      case EntityType.SPEAKER: {
        if (this.action
            && (this.action as Speaker).user) {
          const users = [CommunityUser.hydrate((this.action as Speaker).user as CommunityUser), this.authUser];
          this.openSelectedConversation({
            myUid: this.authUser.uid,
            users,
            groupType: [GroupType.GROUP],
            targetUid: this.entityUid,
            targetType: this.entityType,
          });
        }
        break;
      }
      default:
        break;
    }
  }
}
